import React from 'react';
import { motion } from 'framer-motion';

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

const PageTitle = ({ 
  title, 
  subtitle, 
  icon: Icon, 
  actions,
  className = '' 
}) => {
  return (
    <motion.div 
      variants={fadeInUp} 
      className={`relative mb-8 ${className}`}
    >
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-4">
        <div className="flex items-start gap-3 sm:gap-4 min-w-0">
          {Icon && (
            <div className="flex-shrink-0 p-1.5 sm:p-2 rounded-xl bg-accent-50 dark:bg-accent-900/20 mt-1">
              <Icon className="h-6 w-6 sm:h-8 sm:w-8 text-accent-600 dark:text-accent-400" />
            </div>
          )}
          <div className="min-w-0 flex-1">
            <h1 className="text-xl sm:text-3xl md:text-4xl font-bold tracking-tight text-gray-900 dark:text-white overflow-hidden text-ellipsis pb-1">
              {title}
            </h1>
            {subtitle && (
              <p className="mt-1 sm:mt-2 text-sm sm:text-base md:text-lg text-gray-600 dark:text-gray-400 line-clamp-2">
                {subtitle}
              </p>
            )}
          </div>
        </div>

        {actions && (
          <div className="flex items-center gap-2 sm:gap-3 flex-shrink-0 w-full sm:w-auto justify-end">
            {actions}
          </div>
        )}
      </div>

      {/* Decorative line */}
      <div className="mt-4 sm:mt-6 h-[1px] w-full bg-gradient-to-r from-accent-200/60 via-accent-200/40 to-transparent dark:from-accent-700/60 dark:via-accent-700/40" />
    </motion.div>
  );
};

export default PageTitle; 