import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getAllItemTypes } from '../services/productService';
import { generateFeatures } from '../utils/featureUtils';
import { featuresCacheService } from '../services/featuresCacheService';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const useFeatures = (t) => {
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  // Load features (with background option)
  const loadFeatures = useCallback(async (isBackground = false) => {
    if (!user) {
      setFeatures([]);
      setLoading(false);
      return;
    }

    if (!isBackground) {
      setLoading(true);
    }

    try {
      // Get item types
      const itemTypes = await getAllItemTypes();

      // Filter items based on user role and permissions
      const filteredItems = itemTypes.filter(item => {
        const itemType = item.label.toLowerCase();
        return user?.role === 'admin' || 
               user?.subscription?.features?.dataAnalysis?.itemTypes?.[itemType.replace(' ', '_').toLowerCase()]?.enabled;
      });

      // Generate and transform features
      const baseFeatures = generateFeatures(filteredItems, t, true);
      const transformedFeatures = baseFeatures.map(feature => {
        const baseColor = feature.colors.icon.match(/text-(\w+)-/)?.[1] || 'blue';
        
        return {
          name: feature.name,
          id: feature.id,
          icon: feature.icon,
          colors: feature.colors, // For DashboardLayout
          action: () => navigate(`/dashboard/analysis/${feature.id}`),
          category: 'analysis',
          visible: true,
          // For HomePage format - using correct translation structure
          description: t('sections.analysis.features.dynamic.description', { itemType: feature.name }),
          color: feature.colors.icon,
          bgColor: feature.colors.light,
          hoverColor: `hover:border-${baseColor}-500 dark:hover:border-${baseColor}-400`
        };
      });

      // Cache the features
      if (user?._id) {
        const cacheableFeatures = transformedFeatures.map(({ icon, action, ...rest }) => rest);
        await featuresCacheService.cacheFeatures(user._id, cacheableFeatures);
      }

      setFeatures(transformedFeatures);
      setError(null);

    } catch (err) {
      console.error('Error loading features:', err);
      setError(err);
    } finally {
      if (!isBackground) {
        setLoading(false);
      }
      setIsRefreshing(false);
    }
  }, [user, t, navigate]);

  // Initial load with cache
  useEffect(() => {
    const initialLoad = async () => {
      if (!user?._id) {
        setLoading(false);
        return;
      }

      try {
        // Try to get from cache first
        const cachedFeatures = await featuresCacheService.getCachedFeatures(user._id);
        if (cachedFeatures) {
          // Use cached data immediately
          const restoredFeatures = cachedFeatures.map(feature => ({
            ...feature,
            icon: generateFeatures([{ label: feature.name }], t)[0].icon,
            action: () => navigate(`/dashboard/analysis/${feature.id}`)
          }));
          setFeatures(restoredFeatures);
          setLoading(false);

          // Then refresh in background
          setIsRefreshing(true);
          loadFeatures(true);
        } else {
          // No cache, load normally
          loadFeatures();
        }
      } catch (error) {
        console.error('Cache error:', error);
        loadFeatures();
      }
    };

    initialLoad();
  }, [user?._id, loadFeatures]);

  // Watch for subscription changes
  useEffect(() => {
    if (user?.subscription) {
      setIsRefreshing(true);
      loadFeatures(true);
    }
  }, [user?.subscription, loadFeatures]);

  // Periodic background refresh (every 5 minutes)
  useEffect(() => {
    const refreshInterval = setInterval(() => {
      if (!isRefreshing && user?._id) {
        setIsRefreshing(true);
        loadFeatures(true);
      }
    }, 5 * 60 * 1000);

    return () => clearInterval(refreshInterval);
  }, [user?._id, isRefreshing, loadFeatures]);

  const refreshFeatures = async () => {
    if (!user || isRefreshing) return;
    setIsRefreshing(true);
    await featuresCacheService.clearCache(user._id);
    await loadFeatures(true);
  };

  return { 
    features, 
    loading, 
    error, 
    refreshFeatures,
    isRefreshing 
  };
};