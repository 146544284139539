import { openDB } from 'idb';

const DB_NAME = 'iqureTrade';
const STORE_NAME = 'featuresCache';
const DB_VERSION = 1;
const CACHE_TTL = 24 * 60 * 60 * 1000; // 24 hours

let dbPromise = null;

const initDB = async () => {
  if (!dbPromise) {
    dbPromise = openDB(DB_NAME, DB_VERSION, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          const store = db.createObjectStore(STORE_NAME, { keyPath: 'userId' });
          store.createIndex('timestamp', 'timestamp');
        }
      }
    });
  }
  return dbPromise;
};

export const featuresCacheService = {
  async ensureDB() {
    return await initDB();
  },

  async cacheFeatures(userId, features) {
    try {
      const db = await this.ensureDB();
      await db.put(STORE_NAME, {
        userId,
        features,
        timestamp: Date.now(),
      });
    } catch (error) {
      console.error('Error caching features:', error);
      throw error;
    }
  },

  async getCachedFeatures(userId) {
    try {
      const db = await this.ensureDB();
      const cached = await db.get(STORE_NAME, userId);

      if (!cached) return null;

      // Check if cache is expired
      if (Date.now() - cached.timestamp > CACHE_TTL) {
        await this.clearCache(userId);
        return null;
      }

      return cached.features;
    } catch (error) {
      console.error('Error getting cached features:', error);
      return null;
    }
  },

  async clearCache(userId) {
    try {
      const db = await this.ensureDB();
      await db.delete(STORE_NAME, userId);
    } catch (error) {
      console.error('Error clearing cache:', error);
      throw error;
    }
  }
};

// Initialize DB when service is loaded
initDB().catch(console.error);
