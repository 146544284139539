import React from 'react';
import ReactDOM from 'react-dom/client';
import './i18n';
import './index.css';
import App from './App';
import { DarkModeProvider } from './contexts/DarkModeContext';

import i18next from 'i18next';

i18next.on('initialized', () => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <DarkModeProvider>
      <App />
    </DarkModeProvider>
  );
});